import { ticketRequestDropdownFields, ticketStatusList } from '@f1/shared/src/_formFields';
import { mergeAllFormComponents } from '@f1/shared/src/_formHelpers';
import {
  camelToTitle,
  dataExists,
  formatDateForFEView,
  ignoreCase,
  isEmpty,
  isEqual,
  militaryToAmPm,
  snakeToTitle
} from '@f1/shared/src/_helpers';

const ticketsTemplate = {
  frontend: (schema, version) => {
    if (version === '1.0' && !isEmpty(schema)) {
      const ticketList = formatDataArray(schema.ticketList);
      return {
        ticketList,
        ticketListHeaders: {
          ...Object.keys(ticketList?.[0] || {})?.reduce((acc, aKey) => {
            acc[aKey] = camelToTitle(aKey);
            return acc;
          }, {}),
          // override anything in ticketList object here
          requiredCompletionTime: 'Expected SLA time'
        }
      };
    }
    return { ticketList: [], ticketListHeaders: {} };
  },
  attachToResource: (schema, version) => {
    if (version === '1.0' && !isEmpty(schema)) {
      const relativePath = '';
      return {
        files: formatFileArray(schema?.files, relativePath)
      };
    }
    return {};
  },
  backend: (schema, version) => {
    if (version === '1.0') {
      const {
        currentFormObject,
        formatRefundRequest,
        ticketRequestSubForm,
        descriptionObject,
        requestType,
        isPartner
      } = schema;
      const title = formatTitle(requestType, isPartner);
      const formattedDescription = Object.entries(descriptionObject)
        .map(([key, value]) => `${key}: ${value}`)
        .join('\n');
      const { externalWatchers } = ticketRequestSubForm?.valuesForBackend || {};
      const { refundRequestList, yearMonthOfOriginalCharge } = currentFormObject || {};
      const formattedRefundRequestList = formatRefundRequest
        ? refundRequestList.map((item) => {
            const { valuesForBackend } = item || {};
            return {
              feeName: valuesForBackend.feeName,
              refundReason: valuesForBackend.refundReason,
              yearMonthOfOriginalCharge,
              refundAmountRequested: valuesForBackend.refundAmountRequested
            };
          })
        : [];
      return formatRefundRequest
        ? {
            refundRequestsList: formattedRefundRequestList
          }
        : {
            description: formattedDescription,
            title,
            externalWatchers: !isEmpty(externalWatchers)
              ? externalWatchers.reduce((acc, item) => {
                  const { value } = item || {};
                  // allow portal users to add any emails, including corvia emails, to watchers
                  return acc.concat(value);
                }, [])
              : []
          };
    }
    return {};
  }
};

const formatDataArray = (data) =>
  data?.map((item) => ({
    ticketId: item?.ticketId,
    title: item?.title,
    description: formatDescriptionPortalView(item?.description),
    status: formatStatusFrontend(item?.status),
    createdTimestamp: formatDateForFEView(item?.createdTimestamp, { includeTime: true }),
    requiredCompletionTime: formatDateForFEView(item?.requiredCompletionTime, {
      includeTime: true
    }),
    ticketBusinessCode: item?.ticketBusinessCode,
    portalUserId: item?.requestedByPortalUser?.portalUserId,
    portalUserEmail: item?.requestedByPortalUser?.email,
    watchers: !isEmpty(item?.externalWatchers)
      ? item.externalWatchers
          .reduce((acc, email) => {
            return [...acc, ...(!acc.includes(ignoreCase(email)) ? [email] : [])].sort();
          }, [])
          .join(', ')
      : '-',
    merchantGuid: item?.merchant?.merchantGuid,
    merchantDbaName: item?.merchant?.dbaName,
    merchantLegalName: item?.merchant?.legalName,
    relationshipId: item?.relationship?.relationshipId,
    relationshipName: item?.relationship?.name
  }));

const formatStatusFrontend = (statusEnum) => {
  const formattedEnum = ignoreCase(statusEnum || '');
  const statusMatch =
    ticketStatusList.find((item) => formattedEnum && item.value === formattedEnum) || {};
  return statusMatch.title ?? snakeToTitle(formattedEnum || '');
};

const formatDescriptionPortalView = (text) => {
  // Filters out guid info for portal user description field
  const lines = !isEmpty(text) ? text.trim().split('\n') : [];
  return !isEmpty(lines)
    ? lines
        .filter((line) => {
          const isGuidText = ['relationship id', 'merchant id'].some((id) =>
            ignoreCase(line || '').includes(id)
          );
          return !isGuidText;
        })
        .join('\n')
    : '';
};

const formatFileArray = (files, _relativePath) =>
  files?.map((file) => ({
    fileName: file.name,
    tags: []
  }));

export const formatTitle = (requestType, isPartner) => {
  const requestDropdownFields = ticketRequestDropdownFields({
    userType: isPartner ? 'partner' : 'merchant'
  });
  const ticketReqMatch = requestDropdownFields.find((field) => isEqual(field.value, requestType));
  const ticketTitle =
    (!isEmpty(ticketReqMatch) && ticketReqMatch.title) || camelToTitle(requestType);
  return ticketTitle;
};

export const getDescriptionValueFrontend = (startingValue, comp) => {
  // Converts BE value to FE - only used for displaying values to user,
  // DO NOT USE to populate form fields
  const { componentType, props } = comp || {};
  const { id, fields, list, type } = props || {};
  if (['anyTime', 'time'].includes(type)) {
    return militaryToAmPm(startingValue);
  }
  if (id === 'chargebackNotificationEmailAddresses') {
    return !isEmpty(startingValue) ? startingValue.split('\n').join(' ').trim() : null;
  }
  if (componentType === 'checkboxList') {
    const checkedKeys = !isEmpty(startingValue)
      ? startingValue.reduce((acc, item) => {
          const entries = Object.keys(item);
          const [key] = entries || [];
          const match = !isEmpty(list) ? list.find((li) => isEqual(li.value, key)) : null;
          return acc.concat((match && (match.title || match.value)) || key);
        }, [])
      : null;
    return !isEmpty(checkedKeys) ? checkedKeys.join(', ') : null;
  }
  if (componentType === 'radio') {
    const match = !isEmpty(fields)
      ? fields.find((item) => isEqual(item.value, startingValue))
      : null;
    return (match && (match.label || match.value)) || startingValue;
  }
  if (['combobox', 'dropdown'].includes(ignoreCase(componentType))) {
    const match = !isEmpty(list) ? list.find((item) => isEqual(item.value, startingValue)) : null;
    return (match && (match.title || match.value)) || startingValue;
  }
  return startingValue;
};

export const getDescriptionObject = (components, formData) => {
  const flatFormComps = mergeAllFormComponents(components);
  const formattedDescriptionObject = !isEmpty(formData)
    ? Object.entries(formData).reduce((acc, [key, value]) => {
        const formCompMatch = !isEmpty(flatFormComps)
          ? flatFormComps.find((item) => isEqual(item.props.id, key))
          : null;
        const formattedValue = !isEmpty(formCompMatch)
          ? getDescriptionValueFrontend(value, formCompMatch)
          : null;
        const hasValue = dataExists(formattedValue);
        const useLabelKeys = [
          'dejavooCdpOrDualPricing',
          'paxCdpOrDualPricing',
          'dejavooBypassAvsEmvTapPay',
          'paxBypassAvsEmvTapPay'
        ];
        const camelCaseToTitleCase = (str) => {
          // handles splitting by numbers (not handled by `camelToTitle`)
          // Split the string based on capital letters or numbers
          const words = str.split(/([A-Z0-9][a-z]*)/).filter(Boolean);
          // Capitalize the first letter of each word and join them with spaces
          return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        };
        const formattedKey =
          (useLabelKeys.includes(key) && formCompMatch?.props?.label) || camelCaseToTitleCase(key);
        return hasValue ? { ...acc, [formattedKey]: formattedValue } : acc;
      }, {})
    : {};
  return formattedDescriptionObject;
};

export default ticketsTemplate;
